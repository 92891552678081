<template>
  <v-card outlined>
    <v-subheader class="font-weight-bold">
      Clases
      <v-spacer></v-spacer>
      <v-btn
        v-if="!loading && permisoEscritura && !vistaSeguimiento"
        icon
        x-small
        color="primary"
        :disabled="loading"
        @click="agregarClase = true"
      >
        <v-icon>mdi-text-box-plus-outline</v-icon>
      </v-btn>
    </v-subheader>
    <v-divider class="mx-3"></v-divider>
    <v-card v-if="loading" flat class="my-2">
      <v-skeleton-loader
        type="list-item-avatar-two-line@3"
        style="margin: 20px"
      ></v-skeleton-loader>
    </v-card>
    <div v-else>
      <v-list>
        <draggable
          v-if="!sesionCursoAlumno"
          v-model="clases"
          element="div"
          v-bind="dragOptions"
          ghost-class="ghost"
          handle=".handle"
          @change="cambiarOrden"
        >
          <claseItemComponent
            v-for="(clase, index) in clases"
            :key="`clase-${index}`"
            :tipoCurso="tipoCurso"
            :idCurso="idCurso"
            :clase="clase"
            :tipoModulo="tipoModulo"
            :permisoEscritura="permisoEscritura"
            @claseVisibleModificado="claseVisibleModificado"
          />
        </draggable>

        <claseItemAlumnoComponent
          v-else
          v-for="(clase, index) in clases"
          :key="`claseAlumno-${index}`"
          :tipoCurso="tipoCurso"
          :tipoModulo="tipoModulo"
          :idCurso="idCurso"
          :clase="clase"
        />
        <v-list-item v-if="clases.length == 0">
          <v-list-item-subtitle>No hay clases capturadas</v-list-item-subtitle>
        </v-list-item>
      </v-list>
    </div>

    <agregarClaseComponent
      v-if="agregarClase && permisoEscritura"
      :mostrar="agregarClase"
      :tipoCurso="tipoCurso"
      :idCurso="idCurso"
      :idModulo="idModulo"
      @cancelar="agregarClase = false"
      @claseAgregada="claseAgregada"
    />
  </v-card>
</template>

<script>
import { mapGetters } from "vuex";
import draggable from "vuedraggable";
import claseItemComponent from "./claseItem.vue";
import claseItemAlumnoComponent from "./claseItemAlumno.vue";
import agregarClaseComponent from "./agregarClase.vue";
import { obtenerClasesService, cambiarOrdenClasesService } from "./clases.service";
import { obtenerClasesCampusService } from '../modulosProfesorCampus.service';

export default {
  name: "clasesModulo",
  props: {
    idModulo: String,
    permisoEscritura: Boolean,
  },
  components: {
    claseItemComponent,
    claseItemAlumnoComponent,
    agregarClaseComponent,
    draggable,
  },
  computed: {
    ...mapGetters(["sesionCursoAlumno", "app", "vistaSeguimiento"]),
  },
  created() {
    this.tipoCurso = this.$route.params.tipoCurso;
    this.idCurso = this.$route.params.idCurso;
    this.tipoModulo = this.$route.params.tipoModulo;
    this.cargarClases();
  },
  data() {
    return {
      loading: false,
      tipoCurso: null,
      tipoModulo: null,
      idCurso: null,
      dragOptions: { animation: 100 },
      clases: [],
      clasesAlumno: [],
      agregarClase: false,
    };
  },
  methods: {
    async cargarClases() {
      try {
        this.loading = true;
        let serverResponse = null;
        if (this.tipoModulo == 'moduloProfesor' && this.app == 'campus')
          serverResponse = await obtenerClasesCampusService(this.tipoCurso, this.idCurso, this.idModulo);
        else
          serverResponse = await obtenerClasesService(this.tipoCurso, this.idCurso, this.idModulo);

        this.loading = false;

        if (!serverResponse.ok) {
          this.$systemErrorMessage(serverResponse.mensaje);
        } else {
          this.clases = serverResponse.clases;
        }
      } catch (error) {
        this.loading = false;
        this.$appErrorMessage();
      }
    },
    // verClase(idClase) {
    //   this.$router.push(
    //     `/curso/${this.tipoCurso}/clase/${this.idCurso}/${idClase}`
    //   );
    // },
    claseAgregada(clase) {
      this.clases.push(clase);
    },
    claseVisibleModificado(data) {
      const indexClase = this.clases.findIndex((x) => x._id == data.idClase);
      if (indexClase >= 0) this.clases[indexClase].visible = data.visible;
    },
    async cambiarOrden(event) {
      const ids = {
        idCurso: this.idCurso,
        idModulo: this.idModulo,
        idClase: event.moved.element._id,
      };
      const data = {
        previo: event.moved.oldIndex,
        nuevo: event.moved.newIndex,
      };
      try {
        this.loading = true;
        const serverResponse = await cambiarOrdenClasesService(
          data,
          this.tipoCurso,
          ids
        );
        this.loading = false;
        if (!serverResponse.ok)
          this.$systemErrorMessage(serverResponse.mensaje);
      } catch (error) {
        this.$appErrorMessage();
      }
    },
  },
};
</script>
