import { VBtn } from 'vuetify/lib/components/VBtn';
import { VDivider } from 'vuetify/lib/components/VDivider';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VList } from 'vuetify/lib/components/VList';
import { VListItem } from 'vuetify/lib/components/VList';
import { VListItemIcon } from 'vuetify/lib/components/VList';
import { VListItemTitle } from 'vuetify/lib/components/VList';
import { VMenu } from 'vuetify/lib/components/VMenu';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"text-center"},[_c(VMenu,{attrs:{"offset-x":"","left":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c(VBtn,_vm._g(_vm._b({attrs:{"color":"primary","icon":"","x-small":""}},'v-btn',attrs,false),on),[_c(VIcon,[_vm._v("mdi-dots-vertical")])],1)]}}])},[_c(VList,{attrs:{"dense":""}},[_c(VListItem,{on:{"click":function($event){_vm.modificarRecurso = true}}},[_c(VListItemTitle,[_vm._v("Modificar recurso")]),_c(VListItemIcon,[_c(VIcon,{attrs:{"small":""}},[_vm._v("mdi-pencil")])],1)],1),_c(VListItem,{on:{"click":function($event){_vm.moverRecurso = true}}},[_c(VListItemTitle,[_vm._v("Mover recurso de módulo")]),_c(VListItemIcon,[_c(VIcon,{attrs:{"small":""}},[_vm._v("mdi-share")])],1)],1),_c(VDivider,{staticClass:"mx-3"}),_c(VListItem,{on:{"click":function($event){_vm.eliminarRecurso = true}}},[_c(VListItemTitle,[_c('font',{attrs:{"color":"#D32F2F"}},[_vm._v(" Eliminar recurso ")])],1),_c(VListItemIcon,[_c(VIcon,{attrs:{"color":"red darken-2","small":""}},[_vm._v("mdi-trash-can-outline")])],1)],1)],1)],1),(_vm.modificarRecurso)?_c('agregarRecursoComponent',{attrs:{"mostrar":_vm.modificarRecurso,"tipoCurso":_vm.tipoCurso,"idCurso":_vm.idCurso,"idModulo":_vm.idModulo,"tipo":_vm.recurso.tipo,"editarRecurso":_vm.recurso},on:{"cancelar":function($event){_vm.modificarRecurso = false},"recursoModificado":_vm.recursoModificado}}):_vm._e(),(_vm.eliminarRecurso)?_c('eliminarRecursoComponent',{attrs:{"mostrar":_vm.eliminarRecurso,"idCurso":_vm.idCurso,"recurso":_vm.recurso,"idModulo":_vm.idModulo,"tipoCurso":_vm.tipoCurso},on:{"cancelar":function($event){_vm.eliminarRecurso = false},"eliminarRecurso":_vm.eliminar}}):_vm._e(),(_vm.moverRecurso)?_c('moverObjetoComponent',{attrs:{"mostrar":_vm.moverRecurso,"idCurso":_vm.idCurso,"recurso":_vm.recurso,"idModulo":_vm.idModulo,"tipoCurso":_vm.tipoCurso},on:{"cancelar":function($event){_vm.moverRecurso = false},"recursoCambiado":_vm.eliminar}}):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }