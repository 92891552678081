<template>
  <v-list-item>
    <v-list-item-icon>
      <v-icon v-if="clase.visto" color="green darken-2"
        >mdi-check-circle</v-icon
      >
      <v-icon v-else color="primary">mdi-play-circle</v-icon>
    </v-list-item-icon>
    <v-list-item-content>
      <v-list-item-title>
        <a href="javascript:void(0)" class="link" @click="verClase(clase._id)">
          {{ clase.nombre }}
        </a>
      </v-list-item-title>
      <v-list-item-subtitle>
        <tiempoChip :tiempo="clase.tiempo" style="margin-right: 5px" />
        <genericoChip
          icon="mdi-comment-text-multiple-outline"
          :text="clase.comentarios"
        />
      </v-list-item-subtitle>
    </v-list-item-content>
  </v-list-item>
</template>

<script>
import tiempoChip from "../../../widgets/chips/tiempoChip.vue";
import genericoChip from "../../../widgets/chips/genericoChip.vue";

export default {
  name: "claseItemAlumno",
  components: { tiempoChip, genericoChip },
  props: {
    tipoCurso: String,
    tipoModulo: String,
    idCurso: String,
    clase: Object,
  },
  data() {
    return {
      loading: false,
    };
  },
  methods: {
    verClase(idClase) {
      this.$router.push(
        `/curso/${this.tipoCurso}/${this.idCurso}/${this.tipoModulo}/clase/${idClase}`
      );
    },
  },
};
</script>
