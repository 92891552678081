import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VList } from 'vuetify/lib/components/VList';
import { VListItem } from 'vuetify/lib/components/VList';
import { VListItemIcon } from 'vuetify/lib/components/VList';
import { VListItemTitle } from 'vuetify/lib/components/VList';
import { VMenu } from 'vuetify/lib/components/VMenu';
import { VSkeletonLoader } from 'vuetify/lib/components/VSkeletonLoader';
import { VSpacer } from 'vuetify/lib/components/VGrid';
import { VToolbar } from 'vuetify/lib/components/VToolbar';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c(VContainer,[(!_vm.escuelaTienePermiso)?_c(VSkeletonLoader,{attrs:{"type":"list-item-two-line@5"}}):_c(VCard,{attrs:{"rounded":"lg"}},[_c(VToolbar,{staticClass:"text-h6",attrs:{"dense":"","color":"secondary","dark":"","flat":""}},[_c(VBtn,{attrs:{"icon":""},on:{"click":function($event){return _vm.regresarCurso()}}},[_c(VIcon,[_vm._v("mdi-arrow-left")])],1),_c(VSpacer),_vm._v(" "+_vm._s(_vm.nombreCurso)+" "),_c(VSpacer),(_vm.permisoEscritura && !_vm.vistaSeguimiento)?_c(VMenu,{attrs:{"left":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c(VBtn,_vm._g(_vm._b({attrs:{"small":"","icon":""}},'v-btn',attrs,false),on),[_c(VIcon,[_vm._v("mdi-dots-vertical")])],1)]}}],null,false,3448670655)},[_c(VList,{attrs:{"dense":""}},[_c(VListItem,{on:{"click":function($event){return _vm.modificarModulo()}}},[_c(VListItemTitle,[_vm._v("Modificar módulo")]),_c(VListItemIcon,[_c(VIcon,{staticStyle:{"margin-right":"5px"},attrs:{"small":""}},[_vm._v(" mdi-file-outline ")])],1)],1),_c(VListItem,{on:{"click":function($event){_vm.habilitarEliminar = true}}},[_c(VListItemTitle,[_c('font',{attrs:{"color":"#D32F2F"}},[_vm._v("Eliminar módulo")])],1),_c(VListItemIcon,[_c(VIcon,{staticStyle:{"margin-right":"5px"},attrs:{"color":"red darken-2","small":""}},[_vm._v(" mdi-trash-can-outline ")])],1)],1)],1)],1):_vm._e()],1),(_vm.loading)?_c('moduloLoadingComponent'):_c('contenidoModuloComponent',{attrs:{"modulo":_vm.modulo,"moduloPrevio":_vm.moduloPrevio,"moduloSiguiente":_vm.moduloSiguiente,"idCurso":_vm.idCurso,"tipoCurso":_vm.tipoCurso,"tipoModulo":_vm.tipoModulo,"permisoEscritura":_vm.permisoEscritura,"porcentajeAvance":_vm.porcentajeAvance},on:{"descripcionModificada":_vm.descripcionModificada}})],1),(_vm.agregarModulo && _vm.permisoEscritura)?_c('agregarModuloComponent',{attrs:{"mostrar":_vm.agregarModulo,"idCurso":_vm.idCurso,"tipoCurso":_vm.tipoCurso,"tipo":_vm.tipoModulo,"editarModulo":_vm.editarModulo},on:{"cancelar":function($event){(_vm.agregarModulo = false), (_vm.editarModulo = null)},"moduloModificado":_vm.moduloModificado}}):_vm._e(),(_vm.habilitarEliminar)?_c('eliminar-modulo',{attrs:{"mostrar":_vm.habilitarEliminar,"tipoCurso":_vm.tipoCurso,"idCurso":_vm.idCurso,"idModulo":_vm.idModulo,"tipoModulo":_vm.tipoModulo,"modulo":_vm.modulo},on:{"cancelar":function($event){_vm.habilitarEliminar = false},"moduloEliminado":_vm.regresarCurso}}):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }