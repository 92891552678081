<template>
  <div>
    <v-list-item>
      <v-list-item-icon
        :class="{ handle: dragState == true }"
        @mouseover="permisoEscritura && !vistaSeguimiento ? (dragState = true) : null"
        @mouseout="permisoEscritura ? (dragState = false) : null"
      >
        <v-icon v-if="dragState" color="grey">mdi-drag</v-icon>
        <template v-else>
          <v-progress-circular
            v-if="app == 'campus' && tipoModulo != 'moduloProfesor'"
            :value="clase.procentajeVisto"
            color="primary"
            rotate="-90"
          >
            <span class="porcentaje"> {{ clase.procentajeVisto }}% </span>
          </v-progress-circular>
          <v-icon v-else color="primary">mdi-motion-play-outline</v-icon>
        </template>
      </v-list-item-icon>
      <v-list-item-content>
        <v-list-item-title>
          <switchMiniComponent
            v-if="app == 'campus' && tipoModulo != 'moduloProfesor' && !vistaSeguimiento && permisoEscritura"
            :active="clase.visible"
            :loading="loading"
            :disabled="!permisoEscritura"
            @activate="modificarVisible(clase._id)"
            @deactivate="modificarVisible(clase._id)"
          />
          <a
            href="javascript:void(0)"
            class="link"
            @click="verClase(clase._id)"
          >
            {{ clase.nombre }}
          </a>
        </v-list-item-title>
        <v-list-item-subtitle v-if="tipoModulo != 'moduloProfesor'">
          <tiempoChip :tiempo="clase.tiempo" style="margin-right: 5px" />
          <genericoChip
            v-if="app == 'campus'"
            icon="mdi-comment-text-multiple-outline"
            :text="clase.comentarios"
          />
          <genericoChip
            v-if="app == 'campus'"
            icon="mdi-motion-play-outline"
            :text="clase.reproducciones"
          />
          <likesChip
            v-if="app == 'campus'"
            :likes="clase.likes"
            iconColor="primary"
            style="margin-right: 5px"
          />
          <dislikesChip
            v-if="app == 'campus'"
            :dislikes="clase.dislikes"
            iconColor="primary"
          />
        </v-list-item-subtitle>
      </v-list-item-content>
    </v-list-item>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import switchMiniComponent from "../../../widgets/switchMini.vue";
import tiempoChip from "../../../widgets/chips/tiempoChip.vue";
import genericoChip from "../../../widgets/chips/genericoChip.vue";
import likesChip from "../../../widgets/chips/likesChip.vue";
import dislikesChip from "../../../widgets/chips/dislikesChip.vue";
import {modificarClaseVisibleService} from './clases.service';

export default {
  name: "claseItem",
  components: {
    switchMiniComponent,
    tiempoChip,
    genericoChip,
    likesChip,
    dislikesChip,
  },
  props: {
    tipoCurso: String,
    tipoModulo: String,
    idCurso: String,
    permisoEscritura: Boolean,
    clase: Object,
  },
  computed: {
    ...mapGetters(["app", "vistaSeguimiento"]),
  },
  data() {
    return {
      loading: false,
      tiempo: "",
      dragState: false,
    };
  },
  created() {},
  methods: {
    verClase(idClase) {
      this.$router.push(
        `/curso/${this.tipoCurso}/${this.idCurso}/${this.tipoModulo}/clase/${idClase}`
      );
    },
    async modificarVisible(idClase){
      if(this.permisoEscritura){
        try {
          this.loading = true;
          const serverResponse = await modificarClaseVisibleService(this.idCurso, idClase);
          this.loading = false;
          if (!serverResponse.ok) {
            this.$systemErrorMessage(serverResponse.mensaje);
          } else{
            const emitData ={
              idClase,
              visible: serverResponse.clase.visible
            }
            this.$emit('claseVisibleModificado', emitData)
          }

        } catch (error) {
          this.loading = false;
          this.$appErrorMessage();
        }
      }
    }
  },
};
</script>

<style>
.porcentaje {
  font-size: 10px;
}
</style>
