import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VDivider } from 'vuetify/lib/components/VDivider';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VSpacer } from 'vuetify/lib/components/VGrid';
import { VToolbar } from 'vuetify/lib/components/VToolbar';
import { VTooltip } from 'vuetify/lib/components/VTooltip';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c(VCardTitle,[_c(VBtn,{attrs:{"icon":"","disabled":_vm.hayModuloPrevio,"color":"primary"},on:{"click":function($event){return _vm.moduloAnterior()}}},[_c(VIcon,[_vm._v("mdi-chevron-left")])],1),_c(VSpacer),_vm._v(" "+_vm._s(_vm.modulo.nombre)+" "),_c(VSpacer),_c(VBtn,{attrs:{"icon":"","disabled":_vm.hayModuloSiguiente,"color":"primary"},on:{"click":function($event){return _vm.moduloPosterior()}}},[_c(VIcon,[_vm._v("mdi-chevron-right")])],1)],1),_c(VDivider,{staticClass:"mx-3"}),_c(VContainer,[(_vm.permisoEscritura && !_vm.vistaSeguimiento)?_c('div',[_c(VToolbar,{attrs:{"dense":"","flat":""}},[(!_vm.modulo.descripcion && !_vm.edicionHabilitada)?_c('span',[_c('i',[_vm._v("No se ha capturado descripción")])]):_vm._e(),_c(VSpacer),(_vm.permisoEscritura)?_c(VTooltip,{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('button',_vm._g({staticStyle:{"float":"right"},on:{"click":function($event){_vm.edicionHabilitada = true}}},on),[_c(VIcon,{staticStyle:{"margin-right":"5px"},attrs:{"medium":""}},[_vm._v(" mdi-square-edit-outline ")])],1)]}}],null,false,2360796278)},[_c('span',[_vm._v("Modificar descripción")])]):_vm._e()],1)],1):_vm._e(),(_vm.edicionHabilitada)?_c('div',[_c('editor-texto',{ref:"editorTexto",attrs:{"texto":_vm.modulo.descripcion,"app":"empresa","contenido":_vm.tipoCurso == 'campus' ? 'cursoCampus' : 'cursoPlantilla',"idContenido":_vm.idCurso}}),_c('div',{staticStyle:{"text-align":"right"}},[_c(VSpacer),_c(VBtn,{staticClass:"margen",attrs:{"color":"primary","outlined":"","small":"","loading":_vm.loading},on:{"click":function($event){_vm.edicionHabilitada = false}}},[_vm._v(" Cancelar ")]),_c(VBtn,{staticClass:"margen",attrs:{"color":"primary","dark":"","small":"","loading":_vm.loading},on:{"click":function($event){return _vm.modificarDescripcion()}}},[_vm._v(" Guardar ")])],1)],1):_c('div',{domProps:{"innerHTML":_vm._s(_vm.modulo.descripcion)}})]),_c(VContainer,[_c(VRow,[(_vm.modulo.contieneClases)?_c(VCol,{attrs:{"md":"6","cols":"12"}},[_c('clasesModuloComponent',{attrs:{"idModulo":_vm.modulo._id,"permisoEscritura":_vm.permisoEscritura}})],1):_vm._e(),(_vm.modulo.contieneActividades)?_c(VCol,{attrs:{"md":"6","cols":"12"}},[_c('actividadesModuloComponent',{attrs:{"idModulo":_vm.modulo._id,"permisoEscritura":_vm.permisoEscritura}})],1):_vm._e(),(_vm.modulo.contieneRecursos)?_c(VCol,{attrs:{"md":"6","cols":"12"}},[_c('recursosModuloComponent',{attrs:{"idModulo":_vm.modulo._id,"permisoEscritura":_vm.permisoEscritura}})],1):_vm._e(),(_vm.modulo.contieneExamenes)?_c(VCol,{attrs:{"md":"6","cols":"12","permisoEscritura":_vm.permisoEscritura}},[_c('examenesModuloComponent',{attrs:{"idModulo":_vm.modulo._id,"permisoEscritura":_vm.permisoEscritura}})],1):_vm._e()],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }