<template>
      <v-chip v-if="rounded" :color="color" label small outlined >
        <v-icon small color="primary">mdi-clock-outline</v-icon>
        <span style="margin-left: 5px">{{tiempo}}</span>
      </v-chip>
      <span v-else :color="color" text small >
        <v-icon small color="primary">mdi-clock-outline</v-icon>
        {{tiempo}}
      </span>
</template>

<script>
export default {
  name: "tiempoChip",
  props: {
    tiempo: { type: String, default: '0:00' },
    color: { type: String, default: "" },
    rounded: { type: Boolean, default: false },
  },
};
</script>
