<template>
  <v-container>
    <v-skeleton-loader
      v-if="!escuelaTienePermiso"
      type="list-item-two-line@5"
    ></v-skeleton-loader>
    <v-card v-else rounded="lg">
      <v-toolbar dense color="secondary" dark flat class="text-h6">
        <v-btn icon @click="regresarCurso()">
          <v-icon>mdi-arrow-left</v-icon>
        </v-btn>
        <v-spacer></v-spacer> {{ nombreCurso }} <v-spacer></v-spacer>

        <v-menu v-if="permisoEscritura && !vistaSeguimiento" left>
          <template v-slot:activator="{ on, attrs }">
            <v-btn small icon v-bind="attrs" v-on="on">
              <v-icon>mdi-dots-vertical</v-icon>
            </v-btn>
          </template>
          <v-list dense>
            <v-list-item @click="modificarModulo()">
              <v-list-item-title>Modificar módulo</v-list-item-title>
              <v-list-item-icon>
                <v-icon small style="margin-right: 5px">
                  mdi-file-outline
                </v-icon>
              </v-list-item-icon>
            </v-list-item>
            <v-list-item @click="habilitarEliminar = true">
              <v-list-item-title>
                <font color="#D32F2F">Eliminar módulo</font>
              </v-list-item-title>
              <v-list-item-icon>
                <v-icon color="red darken-2" small style="margin-right: 5px">
                  mdi-trash-can-outline
                </v-icon>
              </v-list-item-icon>
            </v-list-item>
          </v-list>
        </v-menu>
      </v-toolbar>

      <moduloLoadingComponent v-if="loading" />

      <contenidoModuloComponent
        v-else
        :modulo="modulo"
        :moduloPrevio="moduloPrevio"
        :moduloSiguiente="moduloSiguiente"
        :idCurso="idCurso"
        :tipoCurso="tipoCurso"
        :tipoModulo="tipoModulo"
        :permisoEscritura="permisoEscritura"
        :porcentajeAvance="porcentajeAvance"
        @descripcionModificada="descripcionModificada"
      />
    </v-card>

    <agregarModuloComponent
      v-if="agregarModulo && permisoEscritura"
      :mostrar="agregarModulo"
      :idCurso="idCurso"
      :tipoCurso="tipoCurso"
      :tipo="tipoModulo"
      :editarModulo="editarModulo"
      @cancelar="(agregarModulo = false), (editarModulo = null)"
      @moduloModificado="moduloModificado"
    />

    <eliminar-modulo
      v-if="habilitarEliminar"
      :mostrar="habilitarEliminar"
      :tipoCurso="tipoCurso"
      :idCurso="idCurso"
      :idModulo="idModulo"
      :tipoModulo="tipoModulo"
      :modulo="modulo"
      @cancelar="habilitarEliminar = false"
      @moduloEliminado="regresarCurso"
    ></eliminar-modulo>
  </v-container>
</template>

<script>
import moduloLoadingComponent from "./moduloLoading.vue";
import contenidoModuloComponent from "./contenidoModulo.vue";
import agregarModuloComponent from "./agregarModulo.vue";
import { obtenerModuloService } from "./modulos.service";
import { obtenerModuloProfesorService } from "./modulosProfesorCampus.service";
import { mapGetters } from "vuex";
import { permisoMenuEscuela } from "../../../helpers/utilerias";

export default {
  name: "moduloView",
  components: {
    moduloLoadingComponent,
    contenidoModuloComponent,
    agregarModuloComponent,
    "eliminar-modulo": () => import("./eliminarModulo.vue"),
  },
  computed: {
    ...mapGetters(["sessionToken", "app", "role", "menu", "vistaSeguimiento", "sesionCursoAlumno"]),
    puedeModificarModulo() {
      let puede = false;
      if (this.role == "admin") puede = true;
      return puede;
    },
  },
  watch: {
    sessionToken() {
      if (!permisoMenuEscuela("Cursos")) this.$router.push("/dashboard");
      else {
        this.escuelaTienePermiso = true;
        this.cargarModulo();
      }
    },
    "$route.params.idModulo"() {
      if (!permisoMenuEscuela("Cursos")) this.$router.push("/dashboard");
      else {
        this.escuelaTienePermiso = true;
        this.cargarModulo();
      }
    },
  },
  mounted() {
    this.tipoCurso = this.$route.params.tipoCurso;
    this.idCurso = this.$route.params.idCurso;
    this.tipoModulo = this.$route.params.tipoModulo;
    this.idModulo = this.$route.params.idModulo;

    if (this.sessionToken) {
      if (!permisoMenuEscuela("Cursos")) this.$router.push("/dashboard");
      else {
        this.escuelaTienePermiso = true;
        this.cargarModulo();
      }
    }
  },
  data() {
    return {
      loading: false,
      idCurso: null,
      nombreCurso: "Cargando módulo...",
      tipoCurso: "campus",
      permisoEscritura: false,
      modulo: {},
      tipoModulo: null,
      idModulo: null,
      agregarModulo: false,
      editarModulo: null,
      moduloSiguiente: null,
      moduloPrevio: null,
      escuelaTienePermiso: false,
      habilitarEliminar: false,
      porcentajeAvance: null,
    };
  },
  methods: {
    async cargarModulo() {
      try {
        this.loading = true;
        let serverResponse = null;
        this.idModulo = this.$route.params.idModulo;

        if (this.tipoModulo == "moduloProfesor" && this.app == "campus")
          serverResponse = await obtenerModuloProfesorService(
            this.tipoCurso,
            this.idCurso,
            this.idModulo
          );
        else
          serverResponse = await obtenerModuloService(
            this.tipoCurso,
            this.tipoModulo,
            this.idCurso,
            this.idModulo
          );

        if (!serverResponse.ok) {
          this.$systemErrorMessage(serverResponse.mensaje);
        } else {
          this.modulo = serverResponse.modulo;
          this.nombreCurso = serverResponse.nombreCurso;
          this.moduloSiguiente = serverResponse.next;
          this.moduloPrevio = serverResponse.previous;
          this.permisoEscritura = serverResponse.permisoEscritura;
          if (this.tipoCurso === "campus"){
            if (serverResponse.seguimiento == false) this.$store.dispatch("setSesionCursoAlumno",serverResponse.sesionAlumno);
            if (serverResponse.seguimiento == true) this.$store.dispatch("setVistaSeguimiento",serverResponse.seguimiento);
          }
          if(this.sesionCursoAlumno){
            this.porcentajeAvance = this.modulo.avance;
          }
          this.loading = false;
        }
      } catch (error) {
        this.loading = false;
        this.$appErrorMessage();
      }
    },
    regresarCurso() {
      if (this.tipoModulo == "modulo") {
        this.$router.push(`/curso/${this.tipoCurso}/${this.idCurso}#modulos`);
      } else {
        this.$router.push(
          `/curso/${this.tipoCurso}/${this.idCurso}#profesores`
        );
      }
    },
    modificarModulo() {
      this.editarModulo = { ...this.modulo };
      this.agregarModulo = true;
    },
    moduloModificado(modulo) {
      this.modulo = modulo;
    },
    descripcionModificada(descripcion) {
      this.modulo.descripcion = descripcion;
    },
  },
};
</script>
