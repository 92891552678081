<template>
  <v-list-item>
    <v-list-item-icon
      :class="{ handle: dragState == true }"
      @mouseover="permisoEscritura && !vistaSeguimiento ? (dragState = true) : null"
      @mouseout="permisoEscritura ? (dragState = false) : null"
    >
      <v-icon v-if="dragState" color="grey">mdi-drag</v-icon>
      <v-icon v-else color="primary">{{ examenIcon }}</v-icon>
    </v-list-item-icon>
    <v-list-item-content>
      <v-list-item-title>
        <switchMiniComponent
          v-if="tipoCurso == 'campus' && permisoEscritura && !vistaSeguimiento"
          :active="aplicacionLocal.visible"
          :loading="loading"
          @activate="aplicacionLocal.visible = !aplicacionLocal.visible"
          @deactivate="aplicacionLocal.visible = !aplicacionLocal.visible"
        />
        <a
          href="javascript:void(0);"
          class="link"
          @click="$emit('irAAplicacion', aplicacionLocal._id)"
        >
          {{ aplicacionLocal.nombre }}
        </a>
      </v-list-item-title>
      <v-list-item-subtitle>
        {{
          aplicacionLocal.criterio
            ? aplicacionLocal.criterio.nombre
            : "Sin criterio"
        }}
      </v-list-item-subtitle>
    </v-list-item-content>
  </v-list-item>
</template>

<script>
import { mapGetters } from "vuex";
import { actualizarAplicacion } from "../../../cursos/examenAplicaciones/examenAplicacion.service";

import switchMiniComponent from "../../../widgets/switchMini.vue";

export default {
  name: "aplicacionItem",
  props: {
    idCurso: String,
    tipoCurso: String,
    aplicacion: Object,
    permisoEscritura: { type: Boolean, default: false },
  },
  components: {
    switchMiniComponent,
  },
  computed: {
    ...mapGetters(["examenIcon", "app", "vistaSeguimiento"]),
    idModulo() {
      return this.$route.params.idModulo;
    },
  },
  data() {
    return {
      loading: false,
      dragState: false,
      aplicacionLocal: this.aplicacion,
    };
  },
  watch: {
    "aplicacionLocal.visible"(value) {
      this.modificarAplicacion({ visible: value });
    },
  },
  methods: {
    async modificarAplicacion(inputData) {
      this.loading = true;

      try {
        const serverResponse = await actualizarAplicacion({
          idCurso: this.idCurso,
          idModulo: this.idModulo,
          idAplicacion: this.aplicacionLocal._id,
          data: inputData,
        });
        this.loading = false;

        if (!serverResponse.ok)
          this.$systemErrorMessage(serverResponse.mensaje);
      } catch (error) {
        this.loading = false;
        this.$appErrorMessage();
      }
    },
  },
};
</script>
