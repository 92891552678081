import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardActions } from 'vuetify/lib/components/VCard';
import { VDialog } from 'vuetify/lib/components/VDialog';
import { VForm } from 'vuetify/lib/components/VForm';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VListItem } from 'vuetify/lib/components/VList';
import { VListItemContent } from 'vuetify/lib/components/VList';
import { VListItemIcon } from 'vuetify/lib/components/VList';
import { VListItemSubtitle } from 'vuetify/lib/components/VList';
import { VListItemTitle } from 'vuetify/lib/components/VList';
import { VSelect } from 'vuetify/lib/components/VSelect';
import { VSpacer } from 'vuetify/lib/components/VGrid';
import { VTextField } from 'vuetify/lib/components/VTextField';
import { VToolbar } from 'vuetify/lib/components/VToolbar';
import { VToolbarTitle } from 'vuetify/lib/components/VToolbar';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c(VDialog,{attrs:{"max-width":500,"persistent":""},model:{value:(_vm.mostrar),callback:function ($$v) {_vm.mostrar=$$v},expression:"mostrar"}},[_c(VCard,{attrs:{"loading":_vm.loading}},[_c(VToolbar,{staticClass:"text-h6",attrs:{"color":"primary","dark":"","flat":"","dense":""}},[_c(VToolbarTitle,[_vm._v("Agregar actividad")]),_c(VSpacer),_c(VBtn,{attrs:{"fab":"","icon":"","x-small":""},on:{"click":function($event){return _vm.$emit('cancelar')}}},[_c(VIcon,{attrs:{"color":"white"}},[_vm._v("mdi-close")])],1)],1),_c('br'),_c(VForm,{ref:"formulario",staticClass:"formularioClass",attrs:{"lazy-validation":""},model:{value:(_vm.esValido),callback:function ($$v) {_vm.esValido=$$v},expression:"esValido"}},[_c(VSelect,{attrs:{"outlined":"","dense":"","label":"Tipo:","disabled":_vm.loading,"items":_vm.tipos},scopedSlots:_vm._u([{key:"selection",fn:function(ref){
var item = ref.item;
return [_c(VListItem,{attrs:{"dense":""}},[_c(VListItemIcon,[_c(VIcon,{attrs:{"color":"primary"}},[_vm._v(_vm._s(item.icon))])],1),_c(VListItemTitle,[_vm._v(_vm._s(item.text))])],1)]}},{key:"item",fn:function(ref){
var item = ref.item;
var on = ref.on;
var attrs = ref.attrs;
return [_c(VListItem,_vm._g(_vm._b({attrs:{"dense":""}},'v-list-item',attrs,false),on),[_c(VListItemIcon,[_c(VIcon,{attrs:{"color":"primary"}},[_vm._v(_vm._s(item.icon))])],1),_c(VListItemTitle,[_vm._v(_vm._s(item.text))])],1)]}}]),model:{value:(_vm.tipoSelect),callback:function ($$v) {_vm.tipoSelect=$$v},expression:"tipoSelect"}}),_c(VTextField,{attrs:{"outlined":"","dense":"","label":"Nombre","disabled":_vm.loading,"rules":[_vm.reglasComunes.valorRequerido('Nombre requerido')]},model:{value:(_vm.nombre),callback:function ($$v) {_vm.nombre=$$v},expression:"nombre"}}),_c(VSelect,{attrs:{"outlined":"","dense":"","label":"Criterio:","item-value":"_id","item-text":"nombre","disabled":_vm.loading || _vm.criteriosLoading,"items":_vm.criterios,"loading":_vm.criteriosLoading},scopedSlots:_vm._u([{key:"item",fn:function(ref){
var item = ref.item;
return [_c(VListItemContent,[_c(VListItemTitle,[_vm._v(_vm._s(item.nombre))]),(item.seccion)?_c(VListItemSubtitle,[_vm._v(_vm._s(item.seccion))]):_vm._e()],1)]}}]),model:{value:(_vm.criterio),callback:function ($$v) {_vm.criterio=$$v},expression:"criterio"}})],1),_c(VCardActions,[_c(VSpacer),_c(VBtn,{attrs:{"color":"primary","outlined":"","small":"","disabled":_vm.loading},on:{"click":function($event){return _vm.cerrarForm()}}},[_vm._v(" Cancelar ")]),_c(VBtn,{class:{ 'disable-btn': !_vm.esValido },attrs:{"color":"primary","dark":"","small":"","loading":_vm.loading},on:{"click":function($event){return _vm.agregarActividad()}}},[_vm._v(" Guardar ")])],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }