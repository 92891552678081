import { VIcon } from 'vuetify/lib/components/VIcon';
import { VListItem } from 'vuetify/lib/components/VList';
import { VListItemContent } from 'vuetify/lib/components/VList';
import { VListItemIcon } from 'vuetify/lib/components/VList';
import { VListItemSubtitle } from 'vuetify/lib/components/VList';
import { VListItemTitle } from 'vuetify/lib/components/VList';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c(VListItem,[_c(VListItemIcon,{class:{ handle: _vm.dragState == true },on:{"mouseover":function($event){_vm.permisoEscritura && !_vm.vistaSeguimiento ? (_vm.dragState = true) : null},"mouseout":function($event){_vm.permisoEscritura ? (_vm.dragState = false) : null}}},[(_vm.dragState)?_c(VIcon,{attrs:{"color":"grey"}},[_vm._v("mdi-drag")]):_c(VIcon,{attrs:{"color":"primary"}},[_vm._v(_vm._s(_vm.examenIcon))])],1),_c(VListItemContent,[_c(VListItemTitle,[(_vm.tipoCurso == 'campus' && _vm.permisoEscritura && !_vm.vistaSeguimiento)?_c('switchMiniComponent',{attrs:{"active":_vm.aplicacionLocal.visible,"loading":_vm.loading},on:{"activate":function($event){_vm.aplicacionLocal.visible = !_vm.aplicacionLocal.visible},"deactivate":function($event){_vm.aplicacionLocal.visible = !_vm.aplicacionLocal.visible}}}):_vm._e(),_c('a',{staticClass:"link",attrs:{"href":"javascript:void(0);"},on:{"click":function($event){return _vm.$emit('irAAplicacion', _vm.aplicacionLocal._id)}}},[_vm._v(" "+_vm._s(_vm.aplicacionLocal.nombre)+" ")])],1),_c(VListItemSubtitle,[_vm._v(" "+_vm._s(_vm.aplicacionLocal.criterio ? _vm.aplicacionLocal.criterio.nombre : "Sin criterio")+" ")])],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }