<template>
  <v-card outlined>
    <v-subheader class="font-weight-bold">
      Exámenes
      <v-spacer></v-spacer>
      <v-btn
        v-if="!loading && permisoEscritura && !vistaSeguimiento"
        icon
        x-small
        color="primary"
        :disabled="loading"
        @click="mostrarCreacionAplicacion = true"
      >
        <v-icon>mdi-text-box-plus-outline</v-icon>
      </v-btn>
    </v-subheader>
    <v-divider class="mx-3"></v-divider>
    <v-card v-if="loading" flat class="my-2">
      <v-skeleton-loader
        type="list-item-avatar-two-line@3"
        style="margin: 20px"
      ></v-skeleton-loader>
    </v-card>
    <div v-else>
      <v-list>
        <draggable
          v-model="aplicaciones"
          element="div"
          v-bind="dragOptions"
          ghost-class="ghost"
          handle=".handle"
          @change="cambiarOrdenAplicacion"
        >
          <aplicacion-item
            v-for="aplicacion in aplicaciones"
            :key="aplicacion._id"
            :idCurso="idCurso"
            :tipoCurso="tipoCurso"
            :aplicacion="aplicacion"
            :permisoEscritura="permisoEscritura"
            @irAAplicacion="irAAplicacion"
          />
        </draggable>
        <v-list-item v-if="!aplicaciones.length">
          <v-list-item-subtitle
            >No hay exámenes capturados</v-list-item-subtitle
          >
        </v-list-item>
      </v-list>
    </div>

    <agregar-aplicacion
      v-if="mostrarCreacionAplicacion"
      :mostrar="mostrarCreacionAplicacion"
      :tipoCurso="tipoCurso"
      :idCurso="idCurso"
      @cancelar="mostrarCreacionAplicacion = false"
      @aplicacionGuardada="aplicacionGuardada"
    ></agregar-aplicacion>
  </v-card>
</template>

<script>
import { mapGetters } from "vuex";
import draggable from "vuedraggable";
import aplicacionItem from "./aplicacionItem.vue";
import { obtenerAplicacionesService } from "./examenAplicaciones.service";
import { actualizarAplicacion } from "../../../../template/cursos/examenAplicaciones/examenAplicacion.service";

export default {
  name: "examenAplicacionesModulo",
  props: {
    idModulo: String,
    permisoEscritura: { type: Boolean, default: false },
  },
  components: {
    "aplicacion-item": aplicacionItem,
    draggable,
    "agregar-aplicacion": () => import("./agregarAplicacion.vue"),
  },
  mounted() {
    this.tipoCurso = this.$route.params.tipoCurso;
    this.idCurso = this.$route.params.idCurso;
    this.cargarAplicaciones();
  },
  computed: {
    ...mapGetters(["app", "role", "agregarContenidoIcon", "vistaSeguimiento"]),
    puedeAgregarAplicacion() {
      let puede = false;
      if (this.role == "admin") puede = true;
      return puede;
    },
  },
  data() {
    return {
      loading: false,
      idCurso: null,
      tipoCurso: null,
      aplicaciones: [],
      mostrarCreacionAplicacion: false,
      dragOptions: { animation: 100 },
    };
  },
  methods: {
    async cargarAplicaciones() {
      try {
        this.loading = true;
        const serverResponse = await obtenerAplicacionesService(
          this.tipoCurso,
          this.idCurso,
          this.idModulo
        );
        this.loading = false;
        if (!serverResponse.ok) {
          this.$systemErrorMessage(serverResponse.mensaje);
        } else {
          this.aplicaciones = serverResponse.aplicaciones;
        }
      } catch (error) {
        this.loading = false;
        this.$appErrorMessage();
      }
    },

    aplicacionGuardada(aplicacion) {
      this.aplicaciones.push(aplicacion);
      this.mostrarCreacionAplicacion = false;
    },

    async cambiarOrdenAplicacion(event) {
      this.loading = true;

      try {
        const inputData = {
          nuevoIndex: event.moved.newIndex,
        };

        const serverResponse = await actualizarAplicacion({
          idCurso: this.idCurso,
          idModulo: this.idModulo,
          idAplicacion: event.moved.element._id,
          data: inputData,
        });
        this.loading = false;

        if (!serverResponse.ok)
          this.$systemErrorMessage(serverResponse.mensaje);
      } catch (error) {
        this.loading = false;
        this.$appErrorMessage();
      }
    },

    irAAplicacion(idAplicacion) {
      const params = {
        idCurso: this.idCurso,
        tipoCurso: this.tipoCurso,
        idModulo: this.idModulo,
        idAplicacion,
      };

      this.$router.push({
        name: "ExamenAplicacion",
        params,
      });
    },
  },
};
</script>
