<template>
  <div class="text-center">
    <v-menu offset-x left>
      <template v-slot:activator="{ on, attrs }">
        <v-btn color="primary" icon x-small v-bind="attrs" v-on="on">
          <v-icon>mdi-dots-vertical</v-icon>
        </v-btn>
      </template>
      <v-list dense>
        <v-list-item @click="modificarRecurso = true">
          <v-list-item-title>Modificar recurso</v-list-item-title>
          <v-list-item-icon><v-icon small>mdi-pencil</v-icon></v-list-item-icon>
        </v-list-item>
        <v-list-item @click="moverRecurso = true">
          <v-list-item-title>Mover recurso de módulo</v-list-item-title>
          <v-list-item-icon><v-icon small>mdi-share</v-icon></v-list-item-icon>
        </v-list-item>
        <v-divider class="mx-3"></v-divider>
        <v-list-item @click="eliminarRecurso = true">
          <v-list-item-title>
            <font color="#D32F2F"> Eliminar recurso </font>
          </v-list-item-title>
          <v-list-item-icon
            ><v-icon color="red darken-2" small
              >mdi-trash-can-outline</v-icon
            ></v-list-item-icon
          >
        </v-list-item>
      </v-list>
    </v-menu>

    <agregarRecursoComponent
      v-if="modificarRecurso"
      :mostrar="modificarRecurso"
      :tipoCurso="tipoCurso"
      :idCurso="idCurso"
      :idModulo="idModulo"
      :tipo="recurso.tipo"
      :editarRecurso="recurso"
      @cancelar="modificarRecurso = false"
      @recursoModificado="recursoModificado"
    />

    <eliminarRecursoComponent
      v-if="eliminarRecurso"
      :mostrar="eliminarRecurso"
      :idCurso="idCurso"
      :recurso="recurso"
      :idModulo="idModulo"
      :tipoCurso="tipoCurso"
      @cancelar="eliminarRecurso = false"
      @eliminarRecurso="eliminar"
    />

    <moverObjetoComponent
      v-if="moverRecurso"
      :mostrar="moverRecurso"
      :idCurso="idCurso"
      :recurso="recurso"
      :idModulo="idModulo"
      :tipoCurso="tipoCurso"
      @cancelar="moverRecurso = false"
      @recursoCambiado="eliminar"
    />
  </div>
</template>

<script>

import agregarRecursoComponent from "./agregarRecurso.vue";
import eliminarRecursoComponent from "./eliminarRecurso.vue";
import moverObjetoComponent from "../moverObjetoModulo.vue";

export default {
  name: "recursoMenu",
  props: {
    recurso: Object,
    idRecurso: String,
  },
  components: { agregarRecursoComponent, eliminarRecursoComponent, moverObjetoComponent },
  created(){
    this.tipoCurso = this.$route.params.tipoCurso;
    this.idCurso = this.$route.params.idCurso;
    this.idModulo = this.$route.params.idModulo;
  },
  data() {
    return {
      loading: true,
      tipoCurso: null,
      idCurso: null,
      idModulo: null,
      modificarRecurso: false,
      eliminarRecurso: false,
      moverRecurso: false
    };
  },
  methods: {
    recursoModificado(recurso){
      this.$emit("recursoModificado", recurso);
    },
    eliminar(idRecurso){
      this.$emit("eliminarRecurso", idRecurso);
    },
  },
};
</script>
