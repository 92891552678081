<template>
  <v-card outlined>
    <v-subheader class="font-weight-bold">
      Actividades
      <v-spacer></v-spacer>
      <v-btn
        v-if="!loading && permisoEscritura && !vistaSeguimiento"
        icon
        x-small
        color="primary"
        :disabled="loading"
        @click="agregarActividad = true"
      >
        <v-icon>mdi-text-box-plus-outline</v-icon>
      </v-btn>
    </v-subheader>
    <v-divider class="mx-3"></v-divider>
    <v-card v-if="loading" flat class="my-2">
      <v-skeleton-loader
        type="list-item-avatar-two-line@3"
        style="margin: 20px"
      ></v-skeleton-loader>
    </v-card>
    <div v-else>
      <v-list>
        <draggable
          v-if="!sesionCursoAlumno"
          v-model="actividades"
          element="div"
          v-bind="dragOptions"
          ghost-class="ghost"
          handle=".handle"
          @change="cambiarOrden"
        >
          <actividadItemComponent
            v-for="actividad in actividades"
            :key="`actividad-${actividad._id}`"
            :idCurso="idCurso"
            :tipoCurso="tipoCurso"
            :tipoModulo="tipoModulo"
            :actividad="actividad"
            :permisoEscritura="permisoEscritura"
            @click="verActividad()"
            @actividadVisibleModificado="actividadVisibleModificado"
            @actividadCambiada="eliminarActividad"
          />
        </draggable>
        <actividadAlumnoItemComponent
          v-else
          v-for="actividad in actividades"
          :key="`actividad-${actividad._id}`"
          :idCurso="idCurso"
          :tipoCurso="tipoCurso"
          :tipoModulo="tipoModulo"
          :actividad="actividad"
          @click="verActividad()"
        />

        <v-list-item v-if="actividades.length == 0">
          <v-list-item-subtitle
            >No hay actividades capturadas</v-list-item-subtitle
          >
        </v-list-item>
      </v-list>
    </div>

    <agregarActividadComponent
      v-if="agregarActividad"
      :mostrar="agregarActividad"
      :tipoCurso="tipoCurso"
      :idCurso="idCurso"
      :idModulo="idModulo"
      @cancelar="agregarActividad = false"
      @actividadAgregada="actividadAgregada"
    />
  </v-card>
</template>

<script>
import draggable from "vuedraggable";
import { mapGetters } from "vuex";
import actividadItemComponent from "./actividadItem.vue";
import actividadAlumnoItemComponent from "./actividadAlumnoItem.vue";
import agregarActividadComponent from "./agregarActividad.vue";
import {
  obtenerActividadesService,
  cambiarOrdenActividadesService,
} from "./actividades.service";

export default {
  name: "actividadesModulo",
  props: {
    idModulo: String,
    permisoEscritura: { type: Boolean, default: false },
  },
  components: {
    actividadItemComponent,
    actividadAlumnoItemComponent,
    agregarActividadComponent,
    draggable,
  },
  created() {
    this.tipoCurso = this.$route.params.tipoCurso;
    this.idCurso = this.$route.params.idCurso;
    this.tipoModulo = this.$route.params.tipoModulo;
    this.cargarActividades();
  },
  computed: {
    ...mapGetters(["app", "role", "agregarContenidoIcon", "sesionCursoAlumno", "vistaSeguimiento"]),
    puedeAgregarActividad() {
      let puede = false;
      if (this.role == "admin") puede = true;
      return puede;
    },
  },
  data() {
    return {
      loading: false,
      idCurso: null,
      tipoCurso: null,
      tipoModulo: null,
      actividades: [],
      agregarActividad: false,
      dragOptions: { animation: 100 },
    };
  },
  methods: {
    async cargarActividades() {
      try {
        this.loading = true;
        const serverResponse = await obtenerActividadesService(
          this.tipoCurso,
          this.idCurso,
          this.idModulo
        );
        this.loading = false;
        if (!serverResponse.ok) {
          this.$systemErrorMessage(serverResponse.mensaje);
        } else {
          this.actividades = serverResponse.actividades;
        }
      } catch (error) {
        this.loading = false;
        this.$appErrorMessage();
      }
    },
    verActividad(idActividad) {
      this.$router.push(
        `/curso/${this.tipoCurso}/actividad/${this.idCurso}/${idActividad}`
      );
    },
    actividadAgregada(actividad) {
      this.actividades.push(actividad);
      this.agregarActividad = false;
    },
    actividadVisibleModificado(data) {
      const indexActividad = this.actividades.findIndex(
        (x) => x._id == data.idActividad
      );
      if (indexActividad >= 0)
        this.actividades[indexActividad].visible = data.visible;
    },
    async cambiarOrden(event) {
      const ids = {
        idCurso: this.idCurso,
        idModulo: this.idModulo,
        idActividad: event.moved.element._id,
      };
      const data = {
        previo: event.moved.oldIndex,
        nuevo: event.moved.newIndex,
      };
      try {
        this.loading = true;
        const serverResponse = await cambiarOrdenActividadesService(
          data,
          this.tipoCurso,
          ids
        );
        this.loading = false;
        if (!serverResponse.ok)
          this.$systemErrorMessage(serverResponse.mensaje);
      } catch (error) {
        this.loading = false;
        this.$appErrorMessage();
      }
    },
    eliminarActividad(idActividad) {
      const index = this.actividades.findIndex((x) => x._id == idActividad);
      if (index >= 0) this.actividades.splice(index, 1)
    },
  },
};
</script>
