<template>
  <v-btn v-if="btn" :color="labelColor" label small outlined>
    <v-icon v-if="active" small :color="iconColor">mdi-thumb-down</v-icon>
    <v-icon v-else small :color="iconColor">mdi-thumb-down-outline</v-icon>
    <span style="margin-left: 5px">{{ dislikes }}</span>
  </v-btn>
  <span v-else>
    <v-chip v-if="rounded" :color="labelColor" label small outlined>
      <v-icon small :color="iconColor">mdi-thumb-down-outline</v-icon>
      <span style="margin-left: 5px">{{ dislikes }}</span>
    </v-chip>
    <span v-else :color="labelColor" text small>
      <v-icon small :color="iconColor">mdi-thumb-down-outline</v-icon>
      <span style="margin-left: 5px">{{ dislikes }}</span>
    </span>
  </span>
</template>

<script>
export default {
  name: "dislikesChip",
  props: {
    dislikes: { type: Number, default: 0 },
    labelColor: { type: String, default: "" },
    iconColor: { type: String, default: "grey" },
    rounded: { type: Boolean, default: false },
    btn: { type: Boolean, default: false },
    active: { type: Boolean, default: false },
  },
  methods: {
    clicked() {
      this.$emit("clicked");
    },
  },
};
</script>
