import { VIcon } from 'vuetify/lib/components/VIcon';
import { VListItem } from 'vuetify/lib/components/VList';
import { VListItemAction } from 'vuetify/lib/components/VList';
import { VListItemContent } from 'vuetify/lib/components/VList';
import { VListItemIcon } from 'vuetify/lib/components/VList';
import { VListItemSubtitle } from 'vuetify/lib/components/VList';
import { VListItemTitle } from 'vuetify/lib/components/VList';
import { VTooltip } from 'vuetify/lib/components/VTooltip';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c(VListItem,[_c(VListItemIcon,{class:{ handle: _vm.dragState == true },on:{"mouseover":function($event){_vm.permisoEscritura && !_vm.vistaSeguimiento ? (_vm.dragState = true) : null},"mouseout":function($event){_vm.permisoEscritura ? (_vm.dragState = false) : null}}},[(_vm.dragState)?_c(VIcon,{attrs:{"color":"grey"}},[_vm._v("mdi-drag")]):(_vm.recurso.recurso.tipo == 'archivo')?_c(VIcon,{attrs:{"color":"primary"}},[_vm._v(" mdi-file-document-outline ")]):(_vm.recurso.recurso.tipo == 'enlace')?_c(VIcon,{attrs:{"color":"primary"}},[_vm._v(" mdi-link-variant ")]):_vm._e()],1),_c(VListItemContent,[_c(VListItemTitle,[(
          _vm.app == 'campus' &&
          _vm.permisoEscritura &&
          _vm.tipoModulo != 'moduloProfesor' &&
          !_vm.vistaSeguimiento
        )?_c('switchMiniComponent',{attrs:{"active":_vm.recurso.visible,"loading":_vm.loading},on:{"activate":function($event){return _vm.modificarVisible()},"deactivate":function($event){return _vm.modificarVisible()}}}):_vm._e(),(_vm.recurso.tipo == 'archivo')?_c(VTooltip,{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
        var on = ref.on;
        var attrs = ref.attrs;
return [_c(VIcon,_vm._g(_vm._b({attrs:{"color":"grey darken-2","small":"","dark":""}},'v-icon',attrs,false),on),[_vm._v(" mdi-download ")])]}}],null,false,3390780159)},[_c('span',[_vm._v("Descargar")])]):_vm._e(),(_vm.recurso.recurso.tipo == 'archivo')?_c('a',{staticClass:"link",on:{"click":function($event){return _vm.comprobarExistenciaArchivo()}}},[_vm._v(" "+_vm._s(_vm.recurso.recurso.nombre)+" ")]):_c('a',{staticClass:"link",attrs:{"href":_vm.url,"target":"_blank"}},[_vm._v(" "+_vm._s(_vm.recurso.recurso.nombre)+" ")])],1),_c(VListItemSubtitle,[_vm._v(" "+_vm._s(_vm.recurso.recurso.descripcion)+" ")])],1),_c(VListItemAction,[(_vm.permisoEscritura && !_vm.vistaSeguimiento)?_c('recursoMenuComponent',{attrs:{"idRecurso":_vm.recurso._id,"recurso":_vm.recurso},on:{"recursoModificado":_vm.recursoModificado,"eliminarRecurso":_vm.eliminarRecurso}}):_vm._e()],1),(_vm.sinArchivo.mostrar)?_c('noFile',{attrs:{"mostrar":_vm.sinArchivo.mostrar,"nombreArchivo":_vm.sinArchivo.nombre},on:{"cerrar":function($event){_vm.sinArchivo.mostrar = false}}}):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }