<template>
  <v-card outlined>
    <v-subheader class="font-weight-bold"
      >Recursos
      <v-spacer></v-spacer>

      <v-btn 
        v-if="!loading && permisoEscritura && !vistaSeguimiento" 
        x-small 
        icon 
        color="primary" 
        :disabled="loading" 
        @click="agregarRecurso = true"
      >
        <v-icon>mdi-text-box-plus-outline</v-icon>
      </v-btn>
    </v-subheader>
    <v-divider class="mx-3"></v-divider>

    <v-card v-if="loading" flat class="my-2">
      <v-skeleton-loader
        type="list-item-avatar-two-line@3"
        style="margin: 20px"
      ></v-skeleton-loader>
    </v-card>

    <v-card v-else flat>
      <v-list>
        <draggable
          v-model="recursos"
          element="div"
          v-bind="dragOptions"
          ghost-class="ghost"
          handle=".handle"
          @change="cambiarOrden"
        >
          <recursoItemComponent
            v-for="(recurso, index) in recursos"
            :key="`recurso-${index}`"
            :recurso="recurso"
            :tipoCurso="tipoCurso"
            :tipoModulo="tipoModulo"
            :idCurso="idCurso"
            :permisoEscritura="permisoEscritura"
            @recursoModificado="recursoModificado"
            @recursoVisibleModificado="recursoVisibleModificado"
            @eliminarRecurso="eliminarRecurso"
          />
        </draggable>
        <v-list-item v-if="recursos.length == 0">
          <v-list-item-subtitle>No hay recursos capturados</v-list-item-subtitle>
        </v-list-item>
      </v-list>
    </v-card>

    <agregarRecursoComponent
      v-if="agregarRecurso"
      :mostrar="agregarRecurso"
      :tipoCurso="tipoCurso"
      :idCurso="idCurso"
      :idModulo="idModulo"
      :tipo="tipo"
      @cancelar="agregarRecurso = false"
      @recursoAgregado="recursoAgregado"
    />
  </v-card>
</template>

<script>
import draggable from "vuedraggable";
import recursoItemComponent from "./recursoItem.vue";
import agregarRecursoComponent from "./agregarRecurso.vue";
import { obtenerRecursosCampusService } from '../modulosProfesorCampus.service';
import {
  obtenerRecursosService,
  cambiarOrdenRecursosService,
} from "./recursos.service";
import { mapGetters } from "vuex";

export default {
  name: "recursosModulo",
  props: {
    idModulo: String,
    permisoEscritura: { type: Boolean, default: false },
  },
  components: {
    recursoItemComponent,
    agregarRecursoComponent,
    draggable,
  },
  computed: {
    ...mapGetters(["role", "app", "vistaSeguimiento"]),
  },
  data() {
    return {
      loading: false,
      tipoCurso: null,
      idCurso: null,
      tipoModulo: null,
      tipo: null,
      agregarRecurso: false,
      recursos: [],
      dragOptions: { animation: 100 },
    };
  },
  mounted() {
    this.tipoCurso = this.$route.params.tipoCurso;
    this.idCurso = this.$route.params.idCurso;
    this.tipoModulo = this.$route.params.tipoModulo;
    this.getRecursosModulo();
  },
  methods: {
    async getRecursosModulo() {
      try {
        this.loading = true;
        let serverResponse = null;

        if (this.tipoModulo == 'moduloProfesor' && this.app == 'campus')
          serverResponse = await obtenerRecursosCampusService(this.tipoCurso,this.idCurso,this.idModulo);
        else 
          serverResponse = await obtenerRecursosService(this.tipoCurso,this.idCurso,this.idModulo);
        this.loading = false;
        if (!serverResponse.ok) {
          this.$systemErrorMessage(serverResponse.mensaje);
        } else {
          this.recursos = serverResponse.recursos;
        }
      } catch (error) {
        this.$appErrorMessage();
      }
    },
    recursoAgregado(recurso) {
      this.recursos.push(recurso);
    },
    recursoModificado(recurso) {
      const index = this.recursos.findIndex((x) => x._id == recurso._id);
      if (index >= 0) {
        this.recursos[index].recurso = recurso.recurso;
      }
    },
    recursoVisibleModificado(data) {
      const index = this.recursos.findIndex((x) => x._id == data.idRecurso);
      if (index >= 0) this.recursos[index].visible = data.visible;
    },
    async eliminarRecurso(idRecurso) {
      const index = this.recursos.findIndex((x) => x._id == idRecurso);
      if (index >= 0) this.recursos.splice(index, 1);
    },
    async cambiarOrden(event) {
      const ids = {
        idCurso: this.idCurso,
        idRecurso: event.moved.element._id,
      };
      const data = {
        previo: event.moved.oldIndex,
        nuevo: event.moved.newIndex,
      };
      try {
        this.loading = true;
        const serverResponse = await cambiarOrdenRecursosService(
          data,
          this.tipoCurso,
          ids
        );
        this.loading = false;
        if (!serverResponse.ok)
          this.$systemErrorMessage(serverResponse.mensaje);
      } catch (error) {
        this.$appErrorMessage();
      }
    },
  },
};
</script>
