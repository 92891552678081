import { getServerRequest, postServerRequest, putServerRequest, patchServerRequest, deleteServerRequest } from '../../../../services/serverRequest.service';
import { store } from '@/store/store';
const httpURL = store.getters.httpURL;

export async function obtenerClaseService(tipoCurso, idCurso, idClase) {
    const token = store.getters.sessionToken;
    const config = { headers: { token } };
    const url = `${httpURL}/curso/${tipoCurso}/clase/${idCurso}/${idClase}`;
    return await getServerRequest(url, config);
}

export async function obtenerClasesService(tipoCurso, idCurso, idModulo) {
    const token = store.getters.sessionToken;
    const config = { headers: { token } };
    const url = `${httpURL}/curso/${tipoCurso}/clases/${idCurso}/${idModulo}`;
    return await getServerRequest(url, config);
}

export async function agregarClaseService(data, tipoCurso, idCurso, idModulo) {
    const token = store.getters.sessionToken;
    const config = { headers: { token } };
    const url = `${httpURL}/curso/${tipoCurso}/clase/${idCurso}/${idModulo}`;
    return await putServerRequest(url, data, config);
}

export async function agregarVideoClaseService(data, tipoCurso, ids) {
    const token = store.getters.sessionToken;
    const config = { headers: { token } };
    const url = `${httpURL}/curso/${tipoCurso}/clase/video/${ids.idCurso}/${ids.idClase}`;
    return await patchServerRequest(url, data, config);
}

export async function modificarClaseService(data, tipoCurso, idCurso, idClase) {
    const token = store.getters.sessionToken;
    const config = { headers: { token } };
    const url = `${httpURL}/curso/${tipoCurso}/clase/${idCurso}/${idClase}`;
    return await patchServerRequest(url, data, config);
}

export async function modificarDescripcionClaseService(data, tipoCurso, idCurso, idClase) {
    const token = store.getters.sessionToken;
    const config = { headers: { token } };
    const url = `${httpURL}/curso/${tipoCurso}/clase/descripcion/${idCurso}/${idClase}`;
    return await patchServerRequest(url, data, config);
}

export async function modificarClaseVisibleService(idCurso, idClase) {
    const token = store.getters.sessionToken;
    const config = { headers: { token } };
    const url = `${httpURL}/curso/campus/clase/visible/${idCurso}/${idClase}`;
    return await patchServerRequest(url, {}, config);
}

export async function cambiarOrdenClasesService(data, tipoCurso, ids) {
    const token = store.getters.sessionToken;
    const config = { headers: { token } };
    const url = `${httpURL}/curso/${tipoCurso}/clase/mover/${ids.idCurso}/${ids.idModulo}/${ids.idClase}`;
    return await patchServerRequest(url, data, config);
}

export async function eliminarClaseService(data) {
    const token = store.getters.sessionToken;
    const config = { headers: { token } };
    const url = `${httpURL}/curso/${data.tipoCurso}/modulo/clase/${data.idCurso}/${data.idClase}`;
    return await deleteServerRequest(url, config);
}