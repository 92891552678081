<template>
  <v-dialog v-model="mostrar" :max-width="500" persistent>
    <v-card :loading="loading">
      <v-toolbar color="primary" dark flat dense class="text-h6">
        <v-toolbar-title>{{ titulo }}</v-toolbar-title>
        <v-spacer></v-spacer>
        <v-btn fab icon x-small @click="$emit('cancelar')">
          <v-icon color="white">mdi-close</v-icon>
        </v-btn>
      </v-toolbar>

      <br />

      <v-form
        ref="formulario"
        v-model="esValido"
        lazy-validation
        class="formularioClass"
      >
        <v-text-field
          v-model="datosFormulario.nombre"
          outlined
          dense
          label="Nombre"
          :disabled="loading"
          :rules="[reglasComunes.valorRequerido('Nombre requerido')]"
        ></v-text-field>
      </v-form>

      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn
          color="primary"
          outlined
          small
          :disabled="loading"
          @click="cerrarForm()"
        >
          Cancelar
        </v-btn>
        <v-btn
          color="primary"
          dark
          small
          :loading="loading"
          :class="{ 'disable-events': !esValido }"
          @click="agregarClase()"
        >
          Guardar
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { agregarClaseService, modificarClaseService } from "./clases.service";
import { validationMixin } from "vuelidate";

export default {
  props: {
    mostrar: Boolean,
    tipoCurso: String,
    idCurso: String,
    idModulo: String,
    idClase: String,
    editarClase: Object,
  },
  mixins: [validationMixin],
  computed: {
    nombreErrors() {
      const errors = [];
      if (!this.$v.nombre.$dirty) return errors;
      !this.$v.nombre.required && errors.push("Campo requerido.");
      return errors;
    },
    datosFormulario() {
      let nombre = this.editarClase ? this.editarClase.nombre : "";
      return { nombre };
    },
    titulo() {
      let titulo = this.editarClase ? "Modificar Clase" : "Agregar Clase";
      return titulo;
    },
  },
  data: () => ({
    loading: false,
    esValido: true,
    reglasComunes: {
      valorRequerido(textoParaMostrar) {
        return (v) => !!v || textoParaMostrar;
      },
    },
  }),
  created() {},
  methods: {
    async agregarClase() {
      if (this.$refs.formulario.validate()) {
        try {
          this.loading = true;
          const data = { nombre: this.datosFormulario.nombre };
          let serverResponse = null;
          let mensaje = "";

          if (this.editarClase) {
            serverResponse = await modificarClaseService(
              data,
              this.tipoCurso,
              this.idCurso,
              this.idClase
            );
            mensaje = "claseModificada";
          } else {
            serverResponse = await agregarClaseService(
              data,
              this.tipoCurso,
              this.idCurso,
              this.idModulo
            );
            mensaje = "claseAgregada";
          }
          this.loading = false;

          if (!serverResponse.ok) {
            this.$systemErrorMessage(serverResponse.mensaje);
          } else {
            this.$emit(mensaje, serverResponse.clase);
            this.$emit("cancelar");
          }
        } catch (error) {
          this.loading = false;
          this.$appErrorMessage();
        }
      }
    },

    cerrarForm() {
      this.$refs.formulario.reset();
      this.$emit("cancelar");
    },
  },
};
</script>

<style scoped>
.formularioClass {
  padding: 15px;
  padding-bottom: 0;
}
</style>
