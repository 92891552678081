import { getServerRequest, putServerRequest } from '../../../../services/serverRequest.service';
import { store } from '@/store/store';
const httpURL = store.getters.httpURL;

export async function obtenerAplicacionesService(tipoCurso, idCurso, idModulo) {
    const token = store.getters.sessionToken;
    const config = { headers: { token } };
    const url = `${httpURL}/curso/examenAplicaciones/${tipoCurso}/${idCurso}/${idModulo}`;
    return await getServerRequest(url, config);
}

export async function crearAplicacion({ idCurso, idModulo, data }) {
    const token = store.getters.sessionToken;
    const config = { headers: { token } };
    const url = `${httpURL}/curso/examenAplicacion/${idCurso}/${idModulo}`;
    return await putServerRequest(url, data, config)
}
